<template>
  <div class="lineTwoDetails">
    <!--头部banner-->
    <div class="hd_head">
      <div class="hd_head_fanhui" :style="colorState?'background:white;':''">
        <van-icon name="arrow-left" size="22" color="#141212" @click="exit()"   />
        <!--<van-icon name="weapp-nav" size="22" @click="share()" color="#141212"    />-->

      </div>
      <van-swipe class="my-swipe" :show-indicators="false" :autoplay="3000"  >
        <van-swipe-item  v-for="item in content.attachments" :key="item.attachmentId">
          <div class="hd_banner" v-show="item.fileType==1">
            <van-image :src="item.url">
              <template v-slot:error>加载失败</template>
            </van-image>
          </div>
          <div class="hd_banner" v-show="item.fileType==2">
            <video width="375px" height="225px" :src="item.url" controls="controls">
            </video>
          </div>
        </van-swipe-item>
        <van-swipe-item v-if="content.attachments.length==0">
          <div class="hd_banner" >
            <van-image :src="content.cover">
              <template v-slot:error>加载失败</template>
            </van-image>
          </div>
        </van-swipe-item>
      </van-swipe>

      <div class="td_head_au">
        <div class="td_head_au_left td_head_color" v-if="videos && videos.length > 0">视频({{videos.length?videos.length:0}})</div>
        <div class="td_head_au_right">图片({{imgs.length?imgs.length:0}})</div>
      </div>
    </div>
    <div class="ltd_title">
      <p v-text="content.title"></p>
      <div class="ltd_title_bot">
          <span>
            {{content.readNumber}}人感兴趣
          </span>
        <span v-if="false">
            <span style="margin-right: 4px">¥{{content.showPrice/100}}</span>起
          </span>
      </div>
    </div>
    <div class="td-brd"></div>
    <div class="td_tiket" v-if="id">
      <div class="td_tiket_nav">
        <div class="td_tiket_nav_list" v-for="item in  content.groupDetails"  :class="item.groupDetailId==groupDetailId?'td_tiket_nav_color':''" @click="groupDetailId=item.groupDetailId">
          <span>{{item.name}}</span>

        </div>
        <!--<div class="td_tiket_nav_list" v-if="content.groupDetails" :class="code=='XC'?'td_tiket_nav_color':''" @click="code='XC'">-->
        <!--<span>行程</span>-->
        <!--</div>-->
        <div class="td_tiket_nav_list" v-show="code==groupDetailId" @click="groupDetailId='TC'" :class="code==groupDetailId?'td_tiket_nav_color':''">
          <span>套餐</span>

        </div>
        <!--<div class="td_tiket_nav_list">-->
        <!--<span>费用及须知</span>-->

        <!--</div>-->
      </div>
      <div class="td_tiket_list"  v-if="id"   v-for="item in content.commoditys" @click="routerDetails(item)">
        <div class="td_tiket_list_head">
          <span v-text="item.name"></span>
          <span>￥{{item.sellPrice/100}}</span>
        </div>
        <div class="td_tiket_list_bot">
          <span>身份证检票入园      随时可退</span>
          <span>原价 ￥{{item.shopPrice/100}}</span>
        </div>
      </div>
    </div>
    <div class="ltd_step" style=" text-align:justify;text-justify:inter-ideograph;" v-show="item.groupDetailId == groupDetailId" v-for="item in content.groupDetails" v-html="item.content"></div>
    <!--分割线-->
    <div class="td-brd"></div>
    <SHComment
      :isPraise="content.isPraise"
      :isCollect="content.isCollect"
      :bizId="params.groupId"
      :star="content.star"
      :collectNum="content.collect"
      :praiseNum="content.praise"
      :bizType="bizTypes"
      @praise="initDetasils"
      @collect="initDetasils"
    ></SHComment>
  </div>
</template>

<script src="./lineTwoDetails.js"></script>

<style scoped src="./lineTwoDetails.css"></style>
